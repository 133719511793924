var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card__box" }, [
    _c(
      "div",
      {
        staticClass: "card__wrapper",
        class: { "card__wrapper--active": _vm.isOurs }
      },
      [
        _c("div", { staticClass: "card__top" }, [
          _c("div", { staticClass: "card__row" }, [
            _c("div", { staticClass: "card__price" }, [
              _vm._v("$ " + _vm._s(_vm.buyer.maxPrice))
            ]),
            _c("div", { staticClass: "card__cell" }, [
              _c(
                "div",
                {
                  staticClass: "card__button button__icon button__icon--border",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.favorites($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "i-bookmark",
                    class: { "i-bookmark--blue": _vm.buyer.isSaved }
                  })
                ]
              ),
              _vm.user.role === 4 ||
              _vm.user.id === _vm.buyer.responsibleID ||
              _vm.buyer.isResponsibleBlocked
                ? _c(
                    "div",
                    {
                      staticClass:
                        "card__button button__icon button__icon--border",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("Multiselect", {
                        staticClass: "mSelect-dropdown",
                        attrs: {
                          options: _vm.list,
                          searchable: false,
                          "show-labels": false,
                          "reset-after": true,
                          value: _vm.item,
                          placeholder: "",
                          "open-direction": "bottom"
                        },
                        on: { select: _vm.actions }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectionActive
                ? _c(
                    "div",
                    {
                      staticClass:
                        "card__button button__icon button__icon--border",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.select($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        class: {
                          "ri-checkbox-fill": _vm.buyer.isSelected,
                          "ri-checkbox-blank-line": !_vm.buyer.isSelected
                        }
                      })
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]),
        _c("div", { staticClass: "card__middle" }, [
          !_vm.showSettlements
            ? _c("div", { staticClass: "card__field card__field--dark" }, [
                _c("div", { staticClass: "card__district" }, [
                  _c("i", { staticClass: "i-location i-location--blue" }),
                  _c("span", { staticClass: "card__span" }, [
                    _vm._v(_vm._s(_vm.settlements))
                  ])
                ])
              ])
            : _vm._e(),
          _vm.districtsName
            ? _c("div", { staticClass: "card__field card__field--dark" }, [
                _c("div", { staticClass: "card__districts" }, [
                  _c("span", { staticClass: "card__span" }, [
                    _vm._v(_vm._s(_vm.districtsName))
                  ])
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "card__row card__tags" }, [
            _c("div", { staticClass: "card__tag card__tag--yellow" }, [
              _c("span", { staticClass: "card__span" }, [
                _vm._v(_vm._s(_vm.buyer.motivation))
              ])
            ]),
            _c("div", { staticClass: "card__tag card__tag--green" }, [
              _c("span", { staticClass: "card__span" }, [
                _vm._v(_vm._s(_vm.buyerStatus))
              ])
            ]),
            _c("div", { staticClass: "card__tag card__tag--red" }, [
              _c("span", { staticClass: "card__span" }, [
                _vm._v(_vm._s(_vm.buyer.contract))
              ])
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "card__bottom" },
          [
            _c("div", { staticClass: "card__id" }, [
              _vm._v("ID " + _vm._s(_vm.buyer.id))
            ]),
            _vm._l(_vm.buyer.relations, function(rel, i) {
              return _c("Relation", {
                key: i,
                attrs: { relation: rel, type: "card-tile" }
              })
            }),
            _c(
              "div",
              {
                staticClass: "card__agent",
                class: {
                  "card__agent--blocked": _vm.buyer.isResponsibleBlocked
                }
              },
              [
                _c("i", { staticClass: "i-employees card__agent-icon" }),
                _c("span", { staticClass: "card__agent-text" }, [
                  _vm._v(_vm._s(_vm.buyer.responsibleName))
                ])
              ]
            )
          ],
          2
        ),
        _c("div", { staticClass: "card__fields" }, [
          _vm.type === "house"
            ? _c("div", { staticClass: "card__field" }, [
                _c("div", { staticClass: "card__text" }, [
                  _vm._v("Розташування")
                ]),
                _c("div", { staticClass: "card__text" }, [
                  _vm._v(_vm._s(_vm.buyer.range))
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "card__field" }, [
            _c("div", { staticClass: "card__text" }, [_vm._v("К-сть кімнат")]),
            _c("div", { staticClass: "card__text" }, [
              _vm._v(_vm._s(_vm.buyer.noOfRooms))
            ])
          ]),
          _vm.type === "house"
            ? _c("div", { staticClass: "card__field" }, [
                _c("div", { staticClass: "card__text" }, [_vm._v("Вид/Тип")]),
                _c("div", { staticClass: "card__text" }, [
                  _vm._v(_vm._s(_vm.material) + "/" + _vm._s(_vm.buildingType))
                ])
              ])
            : _vm._e(),
          _vm.type === "apartment"
            ? _c("div", { staticClass: "card__field" }, [
                _c("div", { staticClass: "card__text" }, [_vm._v("Вид")]),
                _c("div", { staticClass: "card__text" }, [
                  _vm._v(" " + _vm._s(_vm.material) + " ")
                ])
              ])
            : _vm._e(),
          _vm.type !== "house"
            ? _c("div", { staticClass: "card__field" }, [
                _c("div", { staticClass: "card__text" }, [
                  _vm.type === "apartment"
                    ? _c("span", [_vm._v("Тип")])
                    : _c("span", [_vm._v("Призначення")])
                ]),
                _c("div", { staticClass: "card__text" }, [
                  _vm._v(" " + _vm._s(_vm.buildingType) + " ")
                ])
              ])
            : _vm._e(),
          _vm.type !== "apartment"
            ? _c("div", { staticClass: "card__field" }, [
                _c("div", { staticClass: "card__text" }, [_vm._v("Площа м²")]),
                _vm.type === "house"
                  ? _c("div", { staticClass: "card__text" }, [
                      _vm._v(
                        _vm._s(_vm.buyer.squareLand) +
                          "/" +
                          _vm._s(_vm.buyer.squareTotal)
                      )
                    ])
                  : _c("div", { staticClass: "card__text" }, [
                      _vm._v(_vm._s(_vm.buyer.squareTotal))
                    ])
              ])
            : _vm._e(),
          _vm.type === "apartment"
            ? _c("div", { staticClass: "card__field" }, [
                _c("div", { staticClass: "card__text" }, [_vm._v("Поверх")]),
                _c("div", { staticClass: "card__text" }, [
                  _vm._v(_vm._s(_vm.floor))
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "card__field" }, [
            _vm.type !== "house"
              ? _c("div", { staticClass: "card__text" }, [
                  _vm._v("Ремонт/Авт. опал.")
                ])
              : _c("div", { staticClass: "card__text" }, [
                  _vm._v("Ремонт/Гараж")
                ]),
            _c("div", { staticClass: "card__text" }, [
              _vm.buyer.isRenovated
                ? _c("span", { staticClass: "card__span" }, [_vm._v("Є")])
                : _c("span", { staticClass: "card__span" }, [_vm._v("Немає")]),
              _c("span", { staticClass: "card__span" }, [_vm._v("/")]),
              _vm.type === "house"
                ? _c("span", [
                    _vm.buyer.isGarage
                      ? _c("span", { staticClass: "card__span" }, [_vm._v("Є")])
                      : _c("span", { staticClass: "card__span" }, [
                          _vm._v("Немає")
                        ])
                  ])
                : _c("span", [
                    _c("span", { staticClass: "card__span" }, [
                      _vm._v(_vm._s(_vm.buyer.autonomousHeatType))
                    ])
                  ])
            ])
          ]),
          _c("div", { staticClass: "card__field" }, [
            _c("div", { staticClass: "card__text" }, [
              _vm._v("Останній Дзвінок")
            ]),
            _c("div", { staticClass: "card__text" }, [
              _c("i", { staticClass: "i-calendar" }),
              _vm.buyer.lastCallAt
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.moment(_vm.buyer.lastCallAt).format("DD.MM.YYYY")
                      )
                    )
                  ])
                : _c("span", [_vm._v("-")])
            ])
          ]),
          _c("div", { staticClass: "card__field" }, [
            _c("div", { staticClass: "card__text" }, [_vm._v("Зміна ціни")]),
            _c("div", { staticClass: "card__text" }, [
              _c("i", { staticClass: "i-calendar" }),
              _c("span", { staticClass: "card__span" }, [
                _vm._v(
                  _vm._s(_vm.moment(_vm.buyer.updatedAt).format("DD.MM.YYYY"))
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }